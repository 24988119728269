import { useMemo } from 'react';

import { useUser } from '~app/contexts/user';

export const useDisplayAd = (breakpoint: boolean) => {
  const user = useUser();

  return useMemo(() => {
    if (
      user &&
      (user?.subscription.type === 'PREMIUM' ||
        user?.subscription.type === 'PLUS')
    ) {
      return { display: false };
    }
    return breakpoint ? { display: true } : { display: false };
  }, [user, breakpoint]);
};
