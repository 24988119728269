import { Box } from '@iheartradio/web.companion';
import {
  type Companion,
  COMPANION_CLICK_THROUGH_URL_CLASS,
  CompanionResourceType,
} from '@iheartradio/web.playback';
import { compare, isNil, isNull } from '@iheartradio/web.utilities';
import { memo } from 'react';

export type CompanionAdProps = {
  companion: Companion | null;
  fullscreen?: boolean;
};

export function isImplementedCompanion(companion: Companion) {
  if (isNull(companion)) {
    return false;
  }

  return isNil(companion.resourceType)
    ? false
    : [
        CompanionResourceType.Static,
        CompanionResourceType.IFrame,
        CompanionResourceType.HTML,
      ].includes(companion.resourceType);
}

function CompanionAdBase({ companion, fullscreen = false }: CompanionAdProps) {
  if (isNull(companion)) {
    return null;
  }

  const isImplemented = isImplementedCompanion(companion);

  if (!isImplemented) {
    console.group();
    console.groupCollapsed();
    console.info('Not a Static/IFrame/HTML Companion Ad');
    console.dir(companion, { depth: null });
    console.groupEnd();
  }

  return isNil(companion.content) || !isImplemented ? null : (
    <Box
      css={{
        '& img': fullscreen
          ? {
              minHeight: '40rem',
              minWidth: '40rem',
              aspectRatio: '1 / 1',
              height: '100%',
              objectFit: 'contain',
              width: '100%',
            }
          : {},
        '& a': {
          cursor: 'pointer',
        },
      }}
      dangerouslySetInnerHTML={{ __html: companion.content }}
      data-test="companion-ad-creative"
      onClick={() => {
        if (companion.clickThroughUrl) {
          const img = document.createElement('img');
          img.className = COMPANION_CLICK_THROUGH_URL_CLASS;
          img.width = 0;
          img.height = 0;
          img.src = companion.clickThroughUrl;
          document.body.append(img);
        }
      }}
    />
  );
}

export const CompanionAd = memo(CompanionAdBase, (previousProps, nextProps) => {
  return compare(previousProps, nextProps);
});
