import { Box, media, Player, useMediaQuery } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';
import { useDeepCompareEffect } from '@iheartradio/web.remix-shared/react';
import { isNotBlank } from '@iheartradio/web.utilities';
import { memo, useEffect, useState } from 'react';
import { ClientOnly } from 'remix-utils/client-only';

import { playback } from '~app/playback';
import { adBorderStyle } from '~app/utilities/constants';

import { AdFallback } from './ad-fallback';
import { GoogleAds } from './ads';
import { CompanionAd } from './companion-ad';
import { handler } from './handler';
import { useDisplayTargeting } from './targeting/use-display-targeting';
import { useAdUnit } from './use-ad-unit';
import { useDisplayAd } from './use-display-ad';

export const NAV_AD_SIZES = [
  [300, 250],
  // [300, 600], TODO: disabling until "See what's new" button goes away
] as [number, number][];

const DefaultAdFallback = (
  <AdFallback
    buttons={[
      {
        color: 'white',
        link: 'https://iheart.onelink.me/Ff5B/GetTheApp',
        kind: 'primary',
        size: 'small',
        text: 'Get the app',
      },
    ]}
    copy="Download the free iHeart app today and stream live radio, podcasts, and playlists all in one app"
    title="All your favorite music, radio, and podcasts, All free"
    type="desktop"
  />
);

export const adClickHandler = () => {
  GoogleAds.click();
};

export const NavAd = memo(function NavAd() {
  const [isFullScreen] = Player.useFullScreen();

  const adUnitPath = useAdUnit();

  const playerState = playback.useState();
  const playerAdsState = playback.useAds();

  const [companion, setCompanion] = useState<JSX.Element | null>(null);

  const [displayState, setDisplayState] = useState({
    displayAd: false,
    companionAd: false,
  });

  const { display } = useDisplayAd(useMediaQuery(media.large));

  const targeting = useDisplayTargeting();

  useDeepCompareEffect(() => {
    if (
      display &&
      ((playerAdsState.status === Playback.AdPlayerStatus.Idle &&
        playerState.status === Playback.Status.Idle) ||
        playerAdsState.status === Playback.AdPlayerStatus.Done) &&
      isNotBlank(adUnitPath)
    ) {
      setDisplayState(previousProps => ({
        ...previousProps,
        displayAd: true,
      }));
    } else {
      setDisplayState(previousProps => ({
        ...previousProps,
        displayAd: false,
      }));
    }
  }, [adUnitPath, display, playerAdsState]);

  useDeepCompareEffect(() => {
    if (
      displayState.displayAd &&
      adUnitPath &&
      !displayState.companionAd &&
      !isFullScreen
    ) {
      GoogleAds.receiveTargeting(targeting);
      GoogleAds.addSlot(adUnitPath, NAV_AD_SIZES[0], 'nav', DefaultAdFallback, {
        ccrpos: '2000',
      });
      if (GoogleAds.ready()) {
        GoogleAds.refresh();
      }
    }

    return () => {
      GoogleAds.removeSlot('nav');
    };
  }, [displayState, adUnitPath, targeting, isFullScreen]);

  /* 
  Below code is added to capture the ad click event
  As advert is rendering in the iframe, we can't capture the click event. `blur` event fired when element/window loses focus 
  (on iframe click or link opens in new tab/window), we are capturing that plus we are making sure that active element was advert. 
  If the clicked element is advert then we are firing click event to advert container so that we can do any action(ex: firing analytics event). 
  We are ensuring `blur` event *will* fire by focusing window with `window.focus()`. Throttle is added to prevent multiple taps/clicks.
  */
  useEffect(() => {
    const thisHandler = handler({ containerId: 'nav' });

    window.focus();
    window.addEventListener('blur', thisHandler);

    return () => {
      window.removeEventListener('blur', thisHandler);
    };
  }, []);

  useEffect(() => {
    if (
      isNotBlank(playerAdsState.current) &&
      isNotBlank(playerAdsState.current.companions)
    ) {
      const companionToRender =
        playerAdsState.current.companions.find(companion => {
          return (
            companion.height === NAV_AD_SIZES[0][1] &&
            companion.width === NAV_AD_SIZES[0][0]
          );
        }) ?? null;
      if (companionToRender && !isFullScreen) {
        setCompanion(<CompanionAd companion={companionToRender} />);
        setDisplayState({
          displayAd: false,
          companionAd: true,
        });
      } else {
        setCompanion(null);
        setDisplayState(previousProps => ({
          ...previousProps,
          companionAd: false,
        }));
      }
    } else {
      setCompanion(null);
      setDisplayState(previousProps => ({
        ...previousProps,
        companionAd: false,
      }));
    }
  }, [playerAdsState.current, isFullScreen]);

  return (
    <ClientOnly>
      {() => {
        return displayState.displayAd ? (
          <Box
            css={adBorderStyle}
            data-test="nav-ad-slot"
            display={{ '@medium': 'block' }}
            height={NAV_AD_SIZES[0][1]}
            id="nav"
            onClick={adClickHandler}
            opacity="0"
            transition="opacity 0.5s"
            width={NAV_AD_SIZES[0][0]}
          />
        ) : displayState.companionAd ? (
          <Box
            css={adBorderStyle}
            data-test="nav-ad-companion"
            display={{ '@medium': 'block' }}
            height={NAV_AD_SIZES[0][1]}
            id="nav-companion"
            opacity="1"
            width={NAV_AD_SIZES[0][0]}
          >
            {companion}
          </Box>
        ) : null;
      }}
    </ClientOnly>
  );
});
