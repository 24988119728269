import { type TextProps } from '@iheartradio/web.companion';

import { type DesktopTextStyle, type FallbackContainerStyle } from './types';

export const desktopContainerStyle: FallbackContainerStyle = {
  overflow: 'none',
  background: '$gradient-bright-blue',
  borderRadius: '$6',
  direction: 'column',
  gap: '$8',
  height: '25rem',
  justifyContent: 'space-between',
  outline: {
    dark: '1px solid $gray-500',
    light: '1px solid $gray-200',
  },
  padding: '$16',
  width: '30rem',
} as const;

export const mobileContainerStyle: FallbackContainerStyle = {
  alignItems: 'center',
  background: '$gradient-bright-blue',
  borderRadius: '$6',
  direction: 'row',
  gap: '$8',
  height: '5rem',
  padding: '$0 $8',
  width: '32rem',
} as const;

export const desktopTextStyle: DesktopTextStyle = {
  title: {
    backgroundColor: 'transparent',
    color: '$brand-white',
    kind: 'h5',
  },
  copy: {
    backgroundColor: 'transparent',
    color: '$brand-white',
    kind: 'body-3',
  },
} as const;

export const mobileTextStyle: TextProps = {
  color: '$brand-white',
  kind: 'caption-3',
  wordBreak: 'break-all',
} as const;
