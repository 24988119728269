import {
  Button,
  ChevronRightIcon,
  Flex,
  Link,
  LogomarkWhite,
  LogotypeSecondaryWhite,
  Text,
} from '@iheartradio/web.companion';
import { isNotBlank } from '@iheartradio/web.utilities';

import { adBorderStyle } from '~app/utilities/constants';

import {
  desktopContainerStyle,
  desktopTextStyle,
  mobileContainerStyle,
  mobileTextStyle,
} from './styles';
import { type AdFallbackProps } from './types';

const DesktopAdFallback = ({ title, copy, buttons }: AdFallbackProps) => {
  if (buttons && buttons.length > 2) {
    console.warn('Ad Fallback - only first two buttons will be rendered');
  }
  return (
    <Flex {...desktopContainerStyle} data-test="desktop-ad-fallback">
      <Flex direction="row">
        <LogotypeSecondaryWhite />
      </Flex>
      <Text lines={2} {...desktopTextStyle.title}>
        {title}
      </Text>
      <Text lines={3} {...desktopTextStyle.copy}>
        {copy}
      </Text>
      {isNotBlank(buttons) && (
        <Flex data-test="ad-fallback-buttons" direction="row" gap="$8">
          {buttons?.slice(0, 2).map((button, index) => {
            const key = `ad-fallback-button-${index}`;
            const { text, link, color, kind, size } = button;
            return (
              <Button
                as="a"
                color={color}
                data-test="ad-fallback-button"
                href={link}
                key={key}
                kind={kind}
                rel="noreferrer"
                size={size}
                target="_blank"
              >
                {text}
              </Button>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};

const MobileAdFallback = ({ title, buttons }: AdFallbackProps) => {
  const link = buttons?.find(button => button.link)?.link;

  return isNotBlank(link) ? (
    <Flex
      as={Link}
      data-test="mobile-ad-fallback-link"
      href={link}
      rel="noreferrer"
      target="_blank"
      underline="none"
      {...mobileContainerStyle}
    >
      <Flex width="2.4rem">
        <LogomarkWhite />
      </Flex>
      <Text as="p" lines={1} {...mobileTextStyle}>
        {title}
      </Text>
      <ChevronRightIcon fill="brand-white" />
    </Flex>
  ) : (
    <Flex
      {...mobileContainerStyle}
      css={adBorderStyle}
      data-test="mobile-ad-fallback"
    >
      <Flex width="2.4rem">
        <LogomarkWhite />
      </Flex>
      <Text as="p" lines={1} {...mobileTextStyle}>
        {title}
      </Text>
    </Flex>
  );
};

export const AdFallback = ({
  type,
  ...rest
}: { type: 'desktop' | 'mobile' } & AdFallbackProps) => {
  return type === 'desktop' ? (
    <DesktopAdFallback {...rest} />
  ) : (
    <MobileAdFallback {...rest} />
  );
};
